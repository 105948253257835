(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-overview-external/assets/javascripts/coupon-overview-external.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-overview-external/assets/javascripts/coupon-overview-external.js');
"use strict";

const {
  useRef
} = React;
const {
  useSelector
} = ReactRedux;
const Brand = svs.ui.ReactBrand;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  formatDate
} = svs.components.lbUtils.helpers.formatDate;
const {
  selectTotalAmountOfRows,
  selectIsExternalMsys
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  useCouponContext
} = svs.components.tipsen.couponComponents;
const CouponOverviewExternal = () => {
  const [productBranding] = useBranding();
  const currentDate = useRef(new Date());
  let hours = currentDate.current.getHours();
  let minutes = currentDate.current.getMinutes();
  if (hours < 10) {
    hours = '0'.concat(hours);
  }
  if (minutes < 10) {
    minutes = '0'.concat(minutes);
  }
  const timeUploaded = "".concat(hours, ":").concat(minutes);
  const draw = useCurrentDraw();
  const {
    regCloseTime
  } = draw;
  const {
    couponId
  } = useCouponContext();
  const totalAmountOfRows = useSelector(state => selectTotalAmountOfRows(state, couponId));
  const isExternalMSYS = useSelector(state => selectIsExternalMsys(state, couponId));
  let label = 'Enkelrader';
  if (isExternalMSYS) {
    label = 'M-System';
  }
  return React.createElement("div", {
    className: "pg_coupon_overview_external"
  }, React.createElement("div", {
    className: "pg_coupon_overview_external__heading"
  }, "Produkt"), React.createElement("div", {
    className: "pg_coupon_overview_external__value"
  }, React.createElement(Brand, {
    brand: productBranding,
    size: 300,
    square: true
  })), React.createElement("div", {
    className: "pg_coupon_overview_external__heading"
  }, "Uppladdad"), React.createElement("div", {
    className: "pg_coupon_overview_external__value"
  }, timeUploaded), React.createElement("div", {
    className: "pg_coupon_overview_external__heading"
  }, "Klient"), React.createElement("div", {
    className: "pg_coupon_overview_external__value"
  }, "Filuppladdning"), React.createElement("div", {
    className: "pg_coupon_overview_external__heading"
  }, "Spelstopp"), React.createElement("div", {
    className: "pg_coupon_overview_external__value"
  }, "".concat(formatDate(regCloseTime, true, true, false))), React.createElement("div", {
    className: "pg_coupon_overview_external__heading"
  }, "Antal rader"), React.createElement("div", {
    className: "pg_coupon_overview_external__value"
  }, "".concat(totalAmountOfRows)), React.createElement("div", {
    className: "pg_coupon_overview_external__heading"
  }, "Systemtyp"), React.createElement("div", {
    className: "pg_coupon_overview_external__value"
  }, "".concat(label)));
};
setGlobal('svs.components.tipsen.couponOverviewExternal.CouponOverviewExternal', CouponOverviewExternal);

 })(window);